
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {

}

.bottom {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}
@primary-color: #1890ff;